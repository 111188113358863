.icrConatianer {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: var(--screen-padding);
    margin: var(--component-margin);
    align-items: center;
    justify-content: center;
    text-align: center;
    color: black;
}

.icrConatianer input {
    min-width: 300px;
    max-width: 600px;
    width: 80%;
    padding: 10px 40px;
    margin: 30px auto 50px;
    border-radius: 40px;
    border: 0;
    color: #000;
    font-size: 18px;
    background-color: #E5E5E5;
    outline: none;
}


.icrConatianer table {
    width: clamp(310px, 60vw, 800px);
    max-height: 500px;

}

.icrConatianer table thead {
    /* min-height: 3em; */
    position: sticky;
    top: 0;
    background-color: #378082;
    border-radius: 50px 0px 0px 50px;
    color: white;
}

.icrConatianer table thead .rateColumn {
    margin-right: 22px;
}

.icrConatianer table th,
.icrConatianertable td {

    /* box-sizing: border-box; */
    /* text-align: left; */
    /* box-shadow: 0 0 1.5px -1px; */
    /* width: 100px; */
    /* display: flex; */
    /* align-items: center; */
    font-weight: 400;

}

.icrConatianer table td {
    padding: 15px 0px 15px 20px;
    color: #414042;

}

.icrConatianer table th {

    padding: 5px 0px 5px 20px;
}

.icrConatianer table,
.icrConatianertable table thead,
.icrConatianertable table tbody,
.icrConatianertable table tfoot {
    display: flex;
    flex-direction: column;
}

.icrConatianer table tbody {
    margin-right: 0px;
    overflow-x: auto;
}

.icrConatianer table tr {
    display: flex;
    flex: 1 0;


}

.icrConatianer tr:nth-child(even) {
    background-color: #f2f2f2;
}

.countryColumn {
    width: clamp(100px, 20vw, 33%);
    margin-right: 40px;
    margin: 0px 40px 0px 20px;
}

.codeColumn {
    width: clamp(100px, 20vw, 33%);
    justify-content: center;
    margin: 0px 40px;
}

.rateColumn {
    width: clamp(100px, 20vw, 33%);
    margin-left: 40px;
}

.extend::after {
    content: "";
    background-color: #378082;
    border-radius: 0px 50px 50px 0px;
    height: 100%;
    position: absolute;
    right: -15px;
    width: 20px;
}



.icrConatianer ::-webkit-scrollbar {
    width: 22px;
}

.icrConatianer ::-webkit-scrollbar-track {
    background-color: #dadada;
}

.icrConatianer ::-webkit-scrollbar-thumb {
    background: black;
    height: 50px;
}

.icrConatianer ::-webkit-scrollbar-thumb:hover {
    background: rgb(54, 56, 58);
}

@media only screen and (max-width: 800px) {

    .icrConatianer {
        padding: 0px;
        margin: 0px;
        width: 100%;
    }

    .icrConatianer table {
        width: 100%;
    }

    .icrConatianer table tr {
        flex-wrap: wrap;
    }

    .icrConatianer table td {
        padding: 5px 0px 5px 0px;
    }

    .icrConatianer table td,
    .icrConatianertable table th {
        flex-wrap: wrap;
        justify-content: space-around;

    }

    .countryColumn {
        width: 100%;
        margin: 0px;
        justify-content: flex-start;
        padding-left: 40px;

    }

    .codeColumn {
        margin: 0px;
        justify-content: flex-start;
        padding-left: 40px;
        flex: 1;
    }

    .rateColumn {
        margin: 0px;
        justify-content: flex-end;
        flex: 1;
        padding-right: 10px;
    }

    .icrConatianer thead .countryColumn {
        width: 100%;
        padding-left: 40px;
    }

    .icrConatianer thead .codeColumn {
        flex: 1;
        padding-left: 40px;
    }

    .icrConatianer thead .rateColumn {
        flex: 1;
        margin: 0px !important;
        padding-right: 25px;

    }

    .icrConatianer input {
        width: 80%;
        min-width: unset;
        max-width: unset;

    }

}