h2 {
    font-family: 'Fredoka One', cursive;
}

.faqshort {
    display: flex;
    flex-wrap: wrap;
    width: var(--screen-padding);
    justify-content: space-between;
    margin: var(--component-margin);
    
}

.faqsection {
    flex : 2;
    margin-right: 30px;
    min-width: 310px;
}
.contactusshort {
    flex: 1;
    margin-left: 30px;
}



/*heading styles*/
#accordian h3 {
	cursor: pointer;
    position: relative;
    padding-right: 15px;
}

/*iconfont styles*/
#accordian h3 span {
	margin-right: 10px;
}

#accordian ul {
    margin: 0;
    list-style-type: none;
    padding: 0;
}

#accordian ul h3:after {
    content: "\2304";
  padding: 0px 8px;
  right: 10px;
  z-index: 1;
  position: absolute;
  top: -15px;
  font-size: 30px;
  text-align: center;
  height: 100%;
  pointer-events: none;
}



.rowflex {
    display: flex;
    flex-direction: row;
    color: black;
}

.contactusicon {
 margin-right: 20px; 
}

.contactusbutton {
    text-align: center;
    display: inline-block;
    border-radius: 80px;
    background-color: black;
    color: white;
    text-transform: uppercase;
    padding: 7px;
    min-width: 170px;
    margin: 10px;
}

/* new  */

.accordionItemHeading{
    cursor:pointer;
    /* margin:0px 0px 10px 0px; */
    /* padding:10px; */
    color:black;
    width:98%;
    /* -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    box-sizing: border-box; */
}
.close .accordionItemContent{
    height:0px;
    transition:height 1s ease-out;
    -webkit-transform: scaleY(0);
	-o-transform: scaleY(0);
	-ms-transform: scaleY(0);
	transform: scaleY(0);
    float:left;
    display:block;
    
    
}
.open .accordionItemContent{
    padding: 20px;
    background-color: #fff;
    /* border: 1px solid #ddd; */
    width: 100%;
    margin: 0px 0px 10px 0px;
    display:block;
        box-sizing: border-box;
        -webkit-animation: scale-in-ver-top 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: scale-in-ver-top 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.open .accordionItemHeading{
    margin:0px;
    -webkit-border-top-left-radius: 3px;
    -webkit-border-top-right-radius: 3px;
    -moz-border-radius-topleft: 3px;
    -moz-border-radius-topright: 3px;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    -webkit-border-bottom-right-radius: 0px;
    -webkit-border-bottom-left-radius: 0px;
    -moz-border-radius-bottomright: 0px;
    -moz-border-radius-bottomleft: 0px;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
    /* background-color: #bdc3c7; */
    /* color: #7f8c8d; */
}

#accordian.accordionItem.close h3:after{
    content: "\2304";
    /* padding: 0px 8px; */
    right: 0;
    z-index: 1;
    position: absolute;
    top: -15px;
    font-size: 30px;
    text-align: center;
    height: 100%;
    pointer-events: none;
}

#accordian.accordionItem.open h3:after{
    content: "\2303";
    font-size: 23px ;
    /* padding: 0px 8px; */
    right: 0 !important;
    z-index: 1;
    position: absolute;
    top: 0 !important;
    text-align: center;
    height: 100%;
    pointer-events: none;
}



