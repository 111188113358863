.imagebenefits {
  display: flex;
  flex-direction: row;
  background-color: #59c0a1;
  position: relative;
  flex-wrap: wrap;
  margin-bottom: -20px;
  background-repeat: no-repeat;
  margin-bottom: -20px;
  background-size: 700px;
  /* background-position-x: left; 
    background-position-y: bottom; */
  background-position: -25px 165px;
}

.benefitdetail ul li {
  list-style-type: disc;
  margin-left: 15px;
}

/* .leftbenefitcolumn {
  max-width: 40%;
  min-width: 350px;
  position: relative;
} */

.rightbenefittcolum {
  width: 525px;
  padding: 20px;
  color: #ffffff;
  margin-left: 47%;

}

.imagebenefitsheader {
  font-family: "Fredoka One", cursive;
  font-size: 40px;
  margin: 20px 0 10px 0;

}

.topleftimg {
  position: absolute;
  width: 134px;
  top: 50px;
  left: 60px;
}

.bottomleftimg {
  position: absolute;
  width: 146px;
  top: 690px;
  left: 49px;

}

.toprightimg {
  position: absolute;
  width: 150px;
  top: 50px;
  right: 3%;
}

.bottomrightimg {
  position: absolute;
  width: 103px;
  top: 580px;
  right: 2%;
}

.leftbenefitcolumn img {
  width: 100%;
}

/* accordian  */

.benefitsaccordian {
  margin: 20px 20px 20px 0px;
  max-width: 600px;
}

.benefitacchead {
  background-color: #387F81;
  border-radius: 50px 50px 50px 0;
  font-family: "Fredoka One", cursive;
  font-size: 2em;
  font-weight: 300;
  display: flex;
  position: relative;
  z-index: 2;
}

.morebenefit {
  position: relative;
  /* margin: var(--component-margin); */
  margin: 0 auto 0 0;
  background-color: #ffffff;
  color: #387F81;
  font-family: 'Poppins';
  font-size: 0.5em;
  border-radius: 0 50px 50px 0;
  padding: 5px 30px 2px 10px;
}

.open .morebenefit .benarrow::after {
  content: "\276E";
  font-size: 22px;
  position: absolute;
  transform: rotate(90deg);
  top: 40%;
  left: 35%;
}

.close .morebenefit .benarrow::after {
  content: "\276E";
  font-size: 22px;
  transform: rotate(-90deg);
  top: 40%;
  left: 35%;
  position: absolute;
}

.benhead::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  border-top: 60px solid transparent;
  border-right: 27px solid #ffffff;
  width: 0;

}

.benhead {
  flex: 1;
  padding: 8px 10px 8px 30px;
  position: relative;
  overflow: hidden;

}

.benefitdetail {
  padding: 40px 20px 20px 20px;
  background-color: #387F81;
  margin-top: -30px;
  border-radius: 0 0 50px 50px;
  border: 5px solid #ffffff;
  font-size: 22px;
}

.benefitextra {
  margin: 30px 0 30px 0px;
  }

.close .benefitdetail {
  height: 0px;
  transition: height 1s ease-out;
  -webkit-transform: scaleY(0);
  -o-transform: scaleY(0);
  -ms-transform: scaleY(0);
  transform: scaleY(0);
  /* float:left; */
  display: none;


}

.open .benefitdetail {
  padding: 20px;
  /* background-color: #fff; */
  /* border: 1px solid #ddd; */
  width: 100%;
  display: block;
  -webkit-animation: scale-in-ver-top 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  animation: scale-in-ver-top 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  box-sizing: border-box;
}

@media only screen and (min-width: 1150px) and (max-width: 1300px) {
  .topleftimg {
    position: absolute;
    width: 134px;
    top: 50px;
    left: 60px;
  }

  .bottomleftimg {
    position: absolute;
    width: 146px;
    top: 690px;
    left: 49px;
  }

  .toprightimg {
    position: absolute;
    width: 150px;
    top: 50px;
    right: 40px;
  }

  .bottomrightimg {
    position: absolute;
    width: 103px;
    top: 580px;
    right: 40px;
  }
}

@media only screen and (min-width: 800px) and (max-width: 1150px) {
  .topleftimg {
    position: absolute;
    width: 134px;
    top: 50px;
    left: 5px;
  }

  .bottomleftimg {
    position: absolute;
    width: 146px;
    top: 690px;
    left: 49px;
  }

  .toprightimg {
    position: absolute;
    width: 150px;
    top: 50px;
    right: 5px;
  }

  .bottomrightimg {
    position: absolute;
    width: 103px;
    top: 580px;
    right: 5px;
  }
}

@media only screen and (max-width: 800px) {

  .benefitdetail {
    margin-top: -20px;
    font-size: 16px;
  }


  .topleftimg {
    top: 5px;
    left: 0px;
  }

  .bottomleftimg {
    position: absolute;
    width: 146px;
    top: 400px;
    left: 5px;
    display: none;
  }

  .toprightimg {
    position: absolute;
    width: 150px;
    top: 5px;
    right: 5px;
    display: block;
  }

  .bottomrightimg {
    position: absolute;
    width: 103px;
    top: 285px;
    right: 5px;
    display: none;

  }

  .leftbenefitcolumn {
    max-width: 40%;
    min-width: 310px;
    margin-top: 60px;
    margin-left: -30px;
    z-index: 2;
  }

  .benefitsaccordian {
    margin: 0px;
    font-size: 0.6em;
  }



  .rightbenefittcolum {
    margin-left: unset;
    margin-top: 75px;
    z-index: 3;

  }

  .imagebenefits {
    background: none !important;
  }

  .benefitextra {
    margin-left: 0px;
  }

  .benefitextra p {
    font-size: 1.4em;
  }

  .open .morebenefit .benarrow::after {
    content: "\276E";
    font-size: 22px;
    position: absolute;
    transform: rotate(90deg);
    top: 25%;
    left: 35%;
  }

  .close .morebenefit .benarrow::after {
    content: "\276E";
    font-size: 22px;
    transform: rotate(-90deg);
    top: 25%;
    left: 35%;
    position: absolute;
  }


}

@media only screen and (min-width: 800px) and (max-width: 1280px) {
  .toprightimg {
    display: none;

  }

  .bottomrightimg {

    display: none;

  }

}
