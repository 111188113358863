.ratesCard {
    flex: 1;
    border-radius: 22px;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 40px;
    position: relative;
    z-index: 1;

}

.bestRates {
    font-size: 24px;
    position: absolute;
    top: 0px;
    right: 10px;
    border-radius: 23px 23px 0px 0px;
    padding: 8px 30px 25px;
    z-index: 0;
    font-family: var(--secodary_font_family);
}

.ratesHeaderSpecial {
    width: 380px;
    height: 130px !important;
}

.ratesMainContainer {
    display: flex;
    flex-wrap: wrap-reverse;
    align-items: end;
}

.ratesFooter {
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ratesHeader {
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.productsContainer {
    margin: 0px 0px 10px 0px;
}

.whiteLine {
    border-top: 1px solid white;
    width: 90%;
}

.ratesTopText {
    text-align: center;
    margin: 10px 0px 5px 10px;
    font-size: clamp(25px, 2vw, 18px);
}

.bottomImage {
    max-width: 90%;
}

.topLogo {
    margin: 5px 0px 5px 0px;
    text-align: center;

}

.buttonContainer {
    font-family: var(--secodary_font_family);
}

.ratesLinkedProductsDataContainer {
    flex: 1;
    padding: 0px 10px 0px 30px;
    position: relative;
    overflow: hidden;
    display: flex;

}

.ratesName {
    flex: 1;
    font-size: clamp(13px, 2vw, 18px);
    font-weight: 400;
}

.ratesValue {
    margin-right: 50px;
    font-size: clamp(13px, 2vw, 18px);
    font-weight: 400;


}


/* end special rates component ========== */

.tabs {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 800px;
    margin: 40px auto
}

/* .center{
    display: flex;
    flex-direction: column;
    justify-content: center;
} */

.bundles-container h1 {
    text-align: center;
}

.bundles-container {
    width: var(--screen-padding);
    margin: 0 auto;
}

.bundles-sub-header {
    text-align: center;
}

.tabs label {
    order: 1;
    display: block;
    padding: 1rem 2rem;
    font-size: 25px;
    cursor: pointer;
    background-color: transparent;
    color: #ffffff;
    font-weight: 500;
    border-radius: 10px 10px 0 0;
    transition: background ease 0.2s;
    border-bottom: 3px solid #378082;
    flex: 1;
    text-align: center;
    min-width: 100px;
}

.tabs input[type="radio"] {
    display: none;
}

.tabs input[type="radio"]:checked+label {
    background: #378082;
}

.tabs input[type="radio"]:checked+label+.tab {
    display: block;
}

.tabs .tab {
    order: 99;
    flex-grow: 1;
    width: 100%;
    display: none;
    padding: 1rem;
    max-width: 800px;
    margin: auto;

}

.centerdiv {
    text-align: center;
}

.bundlesaccordian {
    margin: 20px auto;
    max-width: 600px;
    font-family: var(--primary_font_family);
}

.bunhead::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    border-top: 90px solid transparent;
    border-right: 27px solid #ffffff;
    width: 0;

}

.bunhead {
    flex: 1;
    padding: 8px 10px 8px 30px;
    position: relative;
    overflow: hidden;
    display: flex;

}

.bunname {
    flex: 1;
    font-size: clamp(13px, 2vw, 18px);
    font-weight: 400;

}

.bunprice {
    margin-right: 50px;
    font-size: clamp(13px, 2vw, 18px);
    font-weight: 400;


}

.bunacchead {
    background-color: #387F81;
    border: 2px solid #378082;
    border-radius: 50px 50px 50px 0;
    font-size: 20px;
    font-weight: 500;
    display: flex;
    position: relative;
    z-index: 2;
}

.bundle {
    display: flex;
    margin: 10px 0px;
    align-items: center;
}

.bundle img {
    width: 45px;
    height: 45px;
}

.bundledetail {
    width: 96% !important;
    padding: 40px 20px 20px;
    background-color: white;
    color: black;
    margin-top: -30px;
    border-radius: 0 0 50px 50px;
    border: 5px solid #ffffff;
    font-size: 22px;
}


.close .bundledetail {
    height: 0px;
    -webkit-animation: scale-up-ver-bottom 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    animation: scale-up-ver-bottom 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    /* float:left; */
    display: none;


}

.open .bundledetail {
    padding: 40px 20px 20px;
    /* background-color: #fff; */
    /* border: 1px solid #ddd; */
    width: 100%;
    display: block;
    -webkit-animation: scale-in-ver-top 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: scale-in-ver-top 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    box-sizing: border-box;
}

.open .bunarrow::after {
    position: absolute;
    content: "\276E";
    top: 30%;
    font-size: 22px;
    left: 35%;
    ;
    transform: rotate(90deg);
}

.close .bunarrow::after {
    position: absolute;
    content: "\276E";
    top: 30%;
    font-size: 22px;
    left: 35%;
    ;
    transform: rotate(-90deg);
}

.showmore:after {


    content: "\276E";
    font-size: 22px;
    transform: rotate(-90deg);
    text-align: center;
    position: absolute;
}

.showless:after {
    content: "\276E";
    font-size: 22px;
    transform: rotate(90deg);
    text-align: center;
    position: absolute;


}

.showless {
    padding: 0px;
    margin: 0px;
}

.showmore {
    padding: 0px;
    margin: 0px;
}

.iconcontainer {
    display: flex;
    flex-direction: column;
    align-items: center;

}

.iconcontainer:hover {
    cursor: pointer;
}



/* .open .bunacchead{
    border: 3px solid #378082;
} */

.bddescription {
    font-size: clamp(13px, 2vw, 18px);
    font-weight: 500;
    margin-left: 15px;
    width: 100%;
}

.bdtextdisplay ul {
    margin: 0px;
}

.bdmessage {
    font-size: 16px;
    min-width: 230px;
    margin-left: 20px;
    line-height: 18px;
}

.bdtextdisplay {
    font-size: clamp(13px, 2vw, 18px);
    font-weight: 500;
    min-width: 90px;
    margin-left: 20px;
}

.benefitextracontainer {
    display: flex;
    color: #EA222F;
    justify-content: flex-end;
    font-size: 25px;
    align-items: center;

}

.benefitextradesc {
    margin-right: 50px;
    font-weight: 500;
    font-size: clamp(13px, 2vw, 18px);

}

.benefitextraval {
    font-weight: 500;
    min-width: 90px;
    font-size: clamp(13px, 2vw, 18px);

}



.benefitfooter {
    display: flex;
    color: white;
    justify-content: flex-end;
    font-size: 25px;
    background-color: #212B36;
    padding: 20px 0;
    margin: 25px -25px -25px -25px;
    border-radius: 0 0 50px 50px;
}

.benefitfooterdesc {
    margin-right: 50px;
    font-size: clamp(13px, 2vw, 25px);

}

.benefitfooterval {
    min-width: 90px;
    margin-right: 30px;
    text-align: center;
    font-size: clamp(13px, 2vw, 25px);

}

.bundletopleftimg {
    position: absolute;
    width: 134px;
    top: 50px;
    left: 60px;
}

.bundlebottomleftimg {
    position: absolute;
    width: 146px;
    top: 515px;
    left: 60px;

}

.bundletoprightimg {
    position: absolute;
    width: 150px;
    top: 50px;
    right: 8%;
}

.bundlebottomrightimg {
    position: absolute;
    width: 103px;
    top: 530px;
    right: 8%;
}

@media only screen and (min-width: 1150px) and (max-width: 1300px) {
    .bundletopleftimg {
        position: absolute;
        width: 134px;
        top: 50px;
        left: 60px;
    }

    .bundlebottomleftimg {
        position: absolute;
        width: 146px;
        top: 580px;
        left: 60px;
    }

    .bundletoprightimg {
        position: absolute;
        width: 150px;
        top: 50px;
        right: 40px;
    }

    .bundlebottomrightimg {
        position: absolute;
        width: 103px;
        top: 530px;
        right: 40px;
    }
}

@media only screen and (min-width: 800px) and (max-width: 1150px) {
    .bundletopleftimg {
        position: absolute;
        width: 134px;
        top: 50px;
        left: 5px;
    }

    .bundlebottomleftimg {
        position: absolute;
        width: 146px;
        top: 580px;
        left: 5px;
    }

    .bundletoprightimg {
        position: absolute;
        width: 150px;
        top: 50px;
        right: 5px;
    }

    .bundlebottomrightimg {
        position: absolute;
        width: 103px;
        top: 530px;
        right: 5px;
    }
}

@media only screen and (max-width: 800px) {
    .bundletopleftimg {
        top: 5px;
        left: 0px;
        display: none;

    }

    .bundlebottomleftimg {
        position: absolute;
        width: 146px;
        top: 400px;
        left: 5px;
        display: none;
    }

    .bundletoprightimg {
        position: absolute;
        width: 150px;
        top: 5px;
        right: 5px;
        display: none;

    }

    .bundlebottomrightimg {
        position: absolute;
        width: 103px;
        top: 285px;
        right: 5px;
        display: none;

    }
}


@media only screen and (min-width: 340px) and (max-width: 800px) {
    .bdmessage {
        display: none;
    }

    .tabs label {
        padding: 10px;
        min-width: 120px;
        margin-top: 10px;
        max-width: 140px;
        font-size: 20px;
    }

    .tabs .tab {
        padding: 1rem 0px;
    }

    .bunacchead {
        /* font-size: 16px; */
    }

    .bddescription {
        /* font-size: 16px; */

    }

    .bdtextdisplay {
        /* font-size: 16px; */
        text-align: right;

    }

    .benefitextracontainer {
        /* font-size: 16px; */
    }

    .benefitfooter {
        margin: 21px -10px -25px -12px;
    }

    .benefitfooterdesc {
        margin-right: 10px;

    }

    .open .bundledetail {
        padding: 20px 5px;
        margin-top: -22px;

    }



    .benefitextradesc p {
        margin: 0px 0px;
    }

    .benefitextradesc {
        margin-right: unset;
    }

    .benefitextraval {
        text-align: right;
    }

    .bunprice {
        margin-right: 15px;
    }

    .bundles-container {
        width: unset;
    }

    .bundle img {
        width: 25px;
        height: 25px;
    }

    .benefitfooterval {
        text-align: right;
    }



    .bundlesaccordian p {
        margin: 0px;
    }



}
@media only screen and (max-width: 1100px) {
    .ratesCard {
        min-width: 320px !important;
    }

    .ratesHeaderSpecial {
        height: 130px!important;
        width: 100% !important;
    }
}
@media only screen and (max-width: 420px) {
    .ratesCard {
min-width: none;    }

    .ratesHeaderSpecial {
        height: 130px!important;
        width: 100% !important;
    }
}

@media only screen and (max-width: 340px) {


    .benefitextraval {
        text-align: right;
    }



    .open .bundledetai {
        padding: 20px 5px;
        margin-top: -22px;
    }


    .bdmessage {
        display: none;
    }

    .tabs label {
        padding: 10px;
        min-width: 120px;
        margin-top: 10px;
        max-width: 140px;
        font-size: 20px;
    }

    .tabs .tab {
        padding: 1rem 0px;
    }

    .bunacchead {
        /* font-size: 14px; */
    }

    .bddescription {
        /* font-size: 14px; */

    }

    .bdtextdisplay {
        /* font-size: 14px; */
        text-align: right;
        font-weight: 500;
    }

    .benefitextracontainer {
        font-size: 14px;
    }

    .benefitfooter {
        /* font-size: 16px; */
        margin: 15px -25px -25px -25px;
    }

    .benefitfooterdesc {
        /* font-size: 16px; */
        margin-right: 10px;
    }

    .bundle {
        margin: 10px 0px;
    }

    .bundle img {
        width: 25px;
        height: 25px;
    }

    .open .bundledetail {
        padding: 20px 20px 20px 20px;
    }

    .benefitextradesc p {
        margin: 0px 0px;
    }

    .benefitextradesc {
        margin-right: unset;
    }

    .bunprice {
        margin-right: 15px;
    }

    .bundles-container {
        width: unset;
    }

    .benefitfooterval {
        text-align: right;
    }





    .bundlesaccordian p {
        margin: 0px;
    }


}