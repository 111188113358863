.footer {
	color: white;
	width: 90%;
	margin: var(--component-margin);
}


.footer-lists {
	color: #fff;
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
	justify-content: space-evenly;
	align-items: stretch;
	width: 100%;
	font-family: 'Poppins', sans-serif;
	margin-bottom: 20px;
}


.footer-col {
	display: flex;
	flex-direction: column;
	border-right: 1px solid #fff;
	padding: 0 10px 0 30px;
	width: 25%;
	max-width: 230px;
	min-width: 200px;
	margin-top: 20px;

}

.footer-col:last-of-type {
	border-right: 0;
}

.footer-col h6,
.footer-col ul,
.footer-col li,
.footer-col a {
	font-size: 18px;
	font-family: inherit;
	margin: 0;
	padding: 0;
}

.footer-col h6 {
	margin: 10px 0 20px;
}

.footer-col h6 i {
	display: none;
}

.footer-col ul {
	list-style-type: none;
}

.footer-col h6 {
	font-weight: 600;
}

.footer-col li {
	margin: 6px 0;
	min-height: 30px;
}

.footer-social {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	margin: 0px 0px 10px;
	max-height: 30px;
	width: 80%;

}

.footer-social img {
	width: 50%;
	height: 100%;
}

.footer-social-links {
	display: flex;
	flex-direction: row;
	height: 100%;
}

.footer-social-links a {
	display: block;
	width: 30px;
	height: 30px;
	margin: 0 4px;
}

.footer-social-links a img {
	width: 100%;
	height: 100%;
}

.footer-lists.footer-lists-mob {
	flex-direction: column;
}

.footer-lists.footer-lists-mob .footer-col {
	min-height: 0;
	border: 0;
	border-bottom: 1px solid #fff;
	width: 100%;
	margin: 10px 0 0;
	padding: 10px;
}

.footer-lists.footer-lists-mob .footer-col h6 {
	display: flex;
	justify-content: space-between;
}

.footer-lists.footer-lists-mob .footer-col h6 i {
	display: inline-block;
	cursor: pointer;
}
/*Footer Lists*/


.footer-col h6 {
	margin: 10px 0 20px;
}

.footer-col h6 i {
	display: none;
}

.footer-col ul {
	list-style-type: none;
}

.footer-col h6 {
	font-weight: 600;
}

.footer-col li {
	margin: 6px 0;
	min-height: 30px;
}


.footer-social img {
	width: 50%;
	height: 100%;
}

.footer-social-links {
	display: flex;
	flex-direction: row;
	height: 100%;
}

.footer-social-links a {
	display: block;
	width: 30px;
	height: 30px;
	margin: 0 4px;
}

.footer-social-links a img {
	width: 100%;
	height: 100%;
}

.footer-lists.footer-lists-mob {
	flex-direction: column;
}

.footer-lists.footer-lists-mob .footer-col {
	min-height: 0;
	border: 0;
	border-bottom: 1px solid #fff;
	width: 100%;
	margin: 10px 0 0;
	padding: 10px;
}

.footer-lists.footer-lists-mob .footer-col h6 {
	display: flex;
	justify-content: space-between;
}

.footer-lists.footer-lists-mob .footer-col h6 i {
	display: inline-block;
	cursor: pointer;
}

.footer-lists.footer-lists-mob .footer-col.footer-list-open .footer-list-accordian {
	display: block;
}

.footer-lists.footer-lists-mob .footer-col.footer-list-closed .footer-list-accordian {
	display: none;
}

.footer-lists.footer-lists-mob .footer-col.footer-list-closed .footer-list-close-btn {
	display: none;
}

.footer-lists.footer-lists-mob .footer-col.footer-list-open .footer-list-open-btn {
	display: none;
}

.footer-mid {
	color: #fff;
}

.footer-pins {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	margin: 25px 0px;
	flex-wrap: wrap;
}

.footer-pin-block {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	margin: 20px 20px;
	min-width: 200px;
}

.footer-pin-block a {
	margin-left: 5px;
}

.footer-pages {
	display: flex;
	flex-direction: row;
	align-items: center;
	flex-wrap: wrap;
	justify-content: center;
	margin: 25px 0px;
}

.footer-pages a {

	border-right: 1px #fff solid;
	padding: 0 30px;
	margin: 20px 0px;
}

.footer-logos-container {
	text-align: center;
	margin: 20px 0px;

}

.footer-logos {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	margin: 7px;
}

.footer-logos img {
	max-height: 30px;
	margin: 0px 7px;

}

.footer-logo-div {
	margin: 20px 0;
}



@media only screen and (max-width: 600px) {
	.footer-pages a {
		width: 300px;
		text-align: left;
		margin: 5px 0px;
		border: none;
		margin-left: 0%;
	}

	.footer-pages a:first-of-type {
		margin-top: 30px;
	}

	.footer-pages {
		border-top: 1px solid white;
	}


	.footer-logo-div {
		max-height: 30px;
		width: 300px;
		margin: 10px 0px;

	}

	.footer-pin-block {
		margin: 10px 0;
		width: 300px;
		justify-content: flex-start;
		margin-left: 25px;
	}

	.footer-pins{
		justify-content: flex-start;
	}

	.footer-pin-block:first-of-type {
		margin-top: 15px;
	}

	.footer-mid {
		border-top: 1px solid white;
	}

	.footer-col {
		border: none;
		max-width: 320px;
		position: relative;
	}

	.footer-col h6 {
		margin: 10px 0 10px;
	}

	.footer-col .closed {
		display: none;
	}

	.footer-col .open ul{
		display: block;
	}

	.footer-col .open h6::after
	{
		font-size: 40px;
		content: "\2304";
		text-align: center;
	}

	.openFooter .h6::after
	{
		font-size: 21px;
		content: "\2303";
		text-align: center;
		position: absolute;
		right: 0px;
		top: 8px;
	}

	.closedFooter .h6::after
	{
		font-size: 25px;
		content: "\2304";
		text-align: center;
		position: absolute;
		right: 0px;
		top: 0px;
	}

	.footer-lists
	{
		justify-content: left;
	}

	.footer-pages{
		justify-content: flex-start;
	}

	.faqsection .accordionItem
	{
		width: 90%;
	}



}