.cardrow {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    justify-content: space-around;
    width: var(--screen-padding);
    margin: var(--component-margin);
}

.fullwidth {
    width: 100%;
    text-align: center;
}

.colorcard_cta_text
{
    margin-top: 10px;
}

.card {
    flex: 1;
    max-width: 23%;
    min-width: 220px;
    background-color: transparent;
    margin: 20px 10px;
    text-align: center;
    display: flex;
    flex-direction: column;
  }
  
.imagecontainer{
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px 10px 0 0;
    padding: 15px;
    height: 100%;
}

  .card img {
      width: 100%;
  }
  
  .cardcontainer {
    padding: 2px 16px;
    text-align: center;
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  
  .cardheader {
      font-family: "Fredoka One",cursive;
      text-align: center;
      margin: 10px;
      font-weight: 400;
      flex: 1;;
  }
  
  .cardtext {
      margin: 10px;
      text-align: center;
      flex: 1;
  }

  .card_cta_button {
      text-align: center;
      display: inline-block;
      border-radius: 80px;
      background-color: black;
      color: var(--primary-font-color);
      text-transform: uppercase;
      padding: 7px;
      min-width: 170px;
      /* margin: 10px; */
  }

  .card_cta_text {
    text-align: center;
    border-radius: 80px;
    text-transform: uppercase;
    padding: 7px;
    min-width: 200px;
    margin: 10px;
}



/* color card css below  */
.colorcard {
    max-width: 300px;
    min-width: 250px;
    background-color: #ffffff;
    margin: 20px 10px;
    display: flex;
    flex-direction: column;
    border-radius:  10px 10px 0px 0px;
    min-height: 200px;
    align-items: center;
    height: auto;
  }

  .colorcard img {
      /* background-color: var(--primary-color); */
      width: 95%;
      border-radius:  10px 10px 0px 0px;
      height: auto;
  }

  .colorcardcontainer {
    padding: 2px 16px;
    text-align: center;
    margin: -40px 20px 20px;
    background-color: white;
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;

  }
  
  .colorcardheader {
      text-align: center;
      margin: 10px 0px 20px;
      font-size: 23px;
      font-weight: 600;
  }
  
  .colorcardtext {
    margin: 0px 0px 10px;      
    text-align: center;
      flex: 1;
  }

  .colorcardtext p 
  {
      margin: 0px;
  }

  .colorcard_cta_button {
      text-align: center;
      display: inline-block;
      border-radius: 80px;
      background-color: var(--cta-color);
      color: var(--primary-font-color);
      text-transform: uppercase;
      padding: 7px;
      min-width: 170px;
      margin: 10px;
  }

  .colorard_cta_text {
    text-align: center;
    border-radius: 80px;
    text-transform: uppercase;
    padding: 7px;
    min-width: 200px;
    margin: 10px;
}

.cardlink {
    /* color: #378082; */
    text-decoration: underline;
    text-transform: uppercase;
    font-weight: 600;
}

