.devices {
	text-align: center;
	width: var(--screen-padding);
	margin: 0px auto;
}

.savingsMessage
{
	font-size: 23px;
    color: white;
    font-weight: 600;
	background-color: white;
    width: max-content;
    margin: auto;
	padding: 0px 10px;
    border-radius: 2em / 5em;
}

.deviceTitleContainer
{
	flex: 1;
}

.deviceTitlePriceContainer
{
	flex: 1;
	text-align: right;

}

.deviceTitlePrice
{
	display: flex;
	width: 100%;
}

.device-boxes {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
}

.device-box {
	width: 320px;
	border-radius: 10px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
	margin: 30px auto;
	padding: 20px;
}

.device-box img {
	height: 300px;
	display: block;
	margin: 20px 20px 0px;
	width: 200px;
}

.device-title {
	margin: 0px 0 10px;
	font-size: 30px;
	font-weight: 600;
	line-height: 45px;
	flex: 1;
}

.device-price {
	font-size: 57px;
	font-weight: 600;
	line-height: 50px;
	margin: 0px 0px 0px 0px;
	flex: 1;
	color: #378082;
}

.device-small {
	font-size:  clamp(13px, 2.5vw, 16px);
	font-weight: 400;
	line-height: 27px;
	letter-spacing: -0.5px;
	flex: 1;
	margin: 8px 0;

}

.device-small-info
{
	font-size:  18px;
	font-weight: 400;
	line-height: 27px;
	letter-spacing: -0.5px;
	flex: 1;

}

.single-phone {
	text-align: center;
	width: var(--screen-padding);
	margin: 0 auto;
}

.single-phone h3 {
	font-size: clamp(14px, 2vw, 25px);
	font-weight: 600;
	line-height: 45px;
	margin: 0;
	padding-right: 40px;
}

.device-price p {
	margin: 10px 0;
	font-size: clamp(25px, 10vw, 50px)
}

.device-title h3 {
	margin: 10px 0;
	font-size: clamp(20px, 10vw, 30px)
}

.single-phone-columns {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: center;
	align-items: flex-start;
	margin-top: 40px;
}

.single-phone-column-left {
	min-width: 200px;
	width: 45%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.single-phone-column-left img {
	min-width: 200px;
	max-width: 100%;
	max-height: 600px;
	display: block;
	padding: 50px;
	padding: 0 50px;

}

.single-phone-column-right {
	text-align: left;
	min-width: 300px;
	max-width: 450px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
}

.device-specs {

	width: 100%;
	list-style-type: none;
}

.device-specs li {
	margin: 8px 0;
}

.deviceaccordionItem {
	position: relative;
	margin-top: 15px;
	
}

.deviceaccordionItem.open .deviceAccordianHead:after {
    content: "\276E";
	top: 5px;
    font-size: 22px;
    position: absolute;
    left: 93%;
    transform: rotate(90deg);
}

.deviceaccordionItem.close .deviceAccordianHead:after {

    content: "\276E";
	top: 5px;
    font-size: 22px;
    position: absolute;
    left: 93%;
    transform: rotate(-90deg);
}

.deviceaccordionItemContent{
	padding: 0px 20px;


}

.open .deviceaccordionItemContent{
    /* background-color: #fff; */
    /* border: 1px solid #ddd; */
    width: 100%;
    display: block;
    animation: scale-in-ver-top 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    box-sizing: border-box;

}

.close .deviceaccordionItemContent{
	display: none;
}

#accordian.accordionItemDevice {
	font-size: 16px;
	margin: 10px 0;
	width: 100%;
}

#accordian.accordionItemDevice h3 {
	background: #59c0a1;
	color: #fff;
	padding: 5px 20px 5px 10px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

#accordian.accordionItemDevice ul {
	padding: 10px 20px;
}

.single-device-pricing {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	width: 100%;
	padding-top: 20px;
}

.single-device-pricing a {
	margin: 10px 0;
}

.deviceAccordianHead {
	background-color: #59C0A1;
	background-color: #59C0A1;
	padding: 5px 10px;
	font-size: clamp(14px,2vw,20px) !important;

}

.productLine {
	width: 100%;
	border: 1px solid #DFE3E8;
	margin-left: 0px;
}


.specs {
	margin: 10px 0px;
	margin: 10px 0px 0px 0px;
	font-family: var(--primary_font_family);
}

.deviceaccordionContainer {
	margin-bottom: 20px;
}

.device-shadow {
	width: 140px;
	height: 5px;
	border-radius: 50%;
	background-color: rgba(0, 0, 0, 0.3);
	filter: blur(4px);
	margin: 25px 0 20px;

}

.deviceContainer {
	width: 35%;
	min-width: 370px;

}

.devicePriceContainer {
	display: flex;
	flex-direction: column;
	height: 100%;
}

@media all and (max-width: 1340px) {
	.single-device-pricing {
		align-items: center
	}



}