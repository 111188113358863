.brandcardrow {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  /* margin: var(--component-margin); */
  max-width: 1000px;
  margin: 0 auto;


}

.brandCardsSection
{
  margin: 40px 0px;
}

.brandcard {
  max-width: 30%;
  min-width: 300px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  padding: 5px;
  justify-content: center;
  align-items: center;
  height: 75px;
  margin: 0px 8px 15px 8px;
}

.brandcard img {
  width: 100%;
  height: auto;
}

.brandlastrow {
  width: calc(100% - 60px);
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px;
  display: flex;
  margin-bottom: 15px;
}

.brandlastrow img {
  width: 100%;
}

.brandlastrowtext {
  font-family: 'Fredoka One', cursive;
  font-size: 2em;
  color: darkgreen;
}

.brandcardrow a {
  line-height: 0px;
}


.brand2ColumnsRow {
  width: calc(50% - 45px);
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px;
  display: flex;
  margin-bottom: 15px;
  margin-right: 8px;
  margin-left: 8px;
}

.brand2ColumnsRow img {
  width: 100%;
}

.brand2ColumnsRow {
  font-family: 'Fredoka One', cursive;
  font-size: 2em;
  color: darkgreen;
}

.brand2ColumnsRow a {
  line-height: 0px;
}



@media only screen and (max-width: 800px) {
  .brandcardrow {

    gap: unset;


  }

  .brandlastrow {
    width: 290px;
    height: 75px;
  }

  .brand2ColumnsRow{
    width: 290px;
    height: 75px;
  }
}