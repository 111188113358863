.simpleComponent {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: var(--screen-padding);
    margin: var(--component-margin);
    align-items: center;
    justify-content: center;
    text-align: center;
    color: white;
}