.navcontainer {
    display: flex;
    justify-content: center;
    width: 100%;
    text-align: center;
    margin: 0;
    background-color: #378082;
    position: sticky;
    top: 0;
    z-index: 100;
}


.selectedMenuItem .menulink {
    color: #378082;
}



nav {
    padding: 0 15px;
    box-sizing: border-box;
    padding: 0;
    margin: 0px;
    font-family: var(--primary_font_family);
    /* background-color: var(--primary-color); */
    width: 90%;
    font-weight: 600;

}



.menucontainer {
    flex: 1;
    display: flex;
    justify-content: center;
    display: none;
    order: 40;
    min-width: 100%;
    flex-direction: column;
    background-color: #e5e6e6;
    margin: 0px -5.5%;
    align-items: center;

}




.menucontainer a {
    text-decoration: none;
    color: #a9aead;
    font-size: 20px;
    margin: 0px;
    padding: 0px;
}

.hasItems
{
    text-decoration: none;
    color: #a9aead;
    font-size: 20px;
    margin: 0px;
    padding: 0px;
    cursor: pointer;
}

.menu {
    flex-wrap: wrap;
}


.menu {
    padding-inline-start: 0px;
}

.menucontainer li {
    border-bottom: 2px solid #d0d3d3;
    /* box-shadow: 0 4px 6px -5px #a9aead; */
}

.menucontainer a:hover {
    color: #378082;
    border-left: 5px solid #378082;
}

.menucontainer li:hover {
    /* color: #378082;
    border-left: 5px solid #378082; */
}

.menu,
.submenu {
    list-style-type: none;
    margin: 0px;
}

.logo {
    font-size: 20px;
    padding: 7.5px 10px 7.5px 0;
    order: 1;

}

.logoright {
    order: 0;
    width: 100vw;
    display: flex;
    /* display: none; */
    align-items: center;
    margin: 0px -5.5%;
    padding: 5px 3%;
    background-color: #212b36;
    justify-content: flex-end;
}

.logo img {
    height: 75px;

}

.righticons {
    flex: 1;
    text-align: right;
    justify-content: flex-end;
    display: flex;
    gap: 10px;
    align-items: center;
}



.item.button {
    padding: 9px 5px;
}


/* .item:not(.button) a:hover,
.item a:hover::after {
    cursor: pointer;

} */

/* Mobile menu */
.menu {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}

.menu li a {
    display: block;
    padding: 15px 5px;
}

.menu li .hasItems
{
    display: block;
    padding: 15px 5px;
}

.menu li a:active {
    border: none;
}

.menu li.subitem a {
    padding: 15px;
}

.menu .hasItems li.subitem a {
    padding: 15px;
}

.toggle {
    color: white;
    order: 1;
    font-size: 25px;
    text-align: right;
}

.item.button {
    order: 2;
}

.item {
    order: 3;
    width: 100%;
    text-align: center;
    display: none;
}

.activenav .menucontainer {
    display: inherit;
    display: flex;

    /* -webkit-animation: scale-in-ver-top 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both; */
    /* animation: scale-in-ver-top 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both; */
}

.activenav .item {
    display: block;
}



/* Submenu up from mobile screens */
.submenu {
    display: none;
}




.has-submenu i {
    font-size: 16px;
}

.has-submenu i {
    font-size: 16px;
}

.has-submenu>a::after {
    font-family: "Font Awesome 5 Free";
    font-size: 16px;
    line-height: 16px;
    font-weight: 900;
    content: "\f078";
    color: white;
    padding-left: 5px;
}

.subitem a {
    padding: 10px 15px;
}

.hasItems .subitem a {
    padding: 10px 15px;
}

.submenu-activenav {
    background-color: #e5e6e6;
    /* border-radius: 3px; */
    color: #378082;
    border-left: 20px solid #378082;
}

.submenu-activenav .hasItems {
    border-left: none;
    color: #378082 !important;
}


.submenu-activenav .submenu {
    background-color: white;
    /* border-radius: 3px; */
    color: #378082;
    display: block;
    /* border-left: 5px solid #378082; */

}


.logoright img {
    height: 50px;
}




/* Tablet menu */
@media all and (min-width: 700px) {
    .menu {
        justify-content: center;
        padding-inline-start: 0px;

    }

    .logo {
        font-size: 20px;
        padding: 7.5px 10px 7.5px 0;
        order: 1;
    }

    .logo img {
        height: 75px;

    }

    .logoright {
        order: 0;
        width: 100vw;
        display: flex;
        /* display: none; */
        align-items: center;
        margin: 0px -5.5%;
        padding: 5px 5%;
        background-color: #212b36;
        justify-content: flex-end;
    }

    .logoright img {
        height: 50px;
    }

    .righticons {
        flex: 1;
        text-align: right;
        display: flex;
        justify-content: flex-end;
    }

    .item.button {
        width: auto;
        order: 1;
        display: block;
    }

    .toggle {
        color: white;
        flex: 1;
        text-align: right;
        order: 2;
    }

    /* Button up from tablet screen */
    .menu li.button a {
        /* padding: 10px 15px; */
        margin: 5px 0;
        text-align: left;
    }

    .menu .hasItems li.button a {
        /* padding: 10px 15px; */
        margin: 5px 0;
        text-align: left;
    }


    .button.secondary {
        border: 0;
    }



    /* .button a:hover {
        text-decoration: none;
    } */

}

/* Desktop menu */
@media all and (min-width: 1268px) {

    .selectedMenuItem .menulink {
        color: #59c0a1 !important;
    }

    .menu {
        /* align-items: flex-start; */
        flex-wrap: nowrap;
        background: none;
    }

    .menucontainer{
        margin: 0px;
    padding: 0px;
    }

    .menucontainer a {
        color: white;
    }

    .menucontainer .hasItems {
        color: white;
    }

    .logo {
        order: 0;
        flex: 1;
        width: 100%;
        margin-top: unset;
        height: auto;
    }

    .logoright {
        display: flex;
        order: 60;
        flex: 1;
        justify-content: flex-end;
        width: 100%;
        height: auto;
        background-color: transparent;
        margin: 0px 0px;
        padding: 0px;
    }


    #iconId {
        display: none;
    }

    .item {
        order: 1;
        position: relative;
        display: block;
        width: auto;
    }

    .button {
        order: 2;
    }

    .submenu-activenav .submenu {
        display: block;
        position: absolute;
        left: -20%;
        top: 60px;
        background-color: white;
        border: none;
        width: 140%;
    }

    .submenu-activenav .submenu a {
        color: #378082;
        border: 2px solid #d0d3d3;
        padding: 5px 15px !important;


    }

    .submenu-activenav .hasItems .submenu a {
        color: #378082;
        border: 2px solid #d0d3d3;
        padding: 5px 15px !important;


    }




    .submenu-activenav .submenu a:hover {
        color: #a9aead;
        border: 2px solid #d0d3d3;
        border-left: 5px solid #378082;
    }

    .toggle {
        color: white;
        display: none;
    }

    .submenu-activenav {
        border: none;
        background-color: transparent;
        color: white;
    }


    .menucontainer {
        background-color: transparent;
        flex: 8;
        display: flex;
        justify-content: center;
        display: inherit;
        flex-direction: row;
        min-width: 10% !important;
    }

    .menucontainer a {
        font-size: 16px;
    }

    .menucontainer .hasItems{
        font-size: 16px;
    }

    .menucontainer li {
        border-bottom: none;

    }

    .item {
        padding: 10px;
    }

    .menucontainer a:hover {
        color: #a9aead;
        border: none;
    }


    .menucontainer a:focus {
        color: white;
    }

    .submenu-activenav .hasItems {
        color: white !important;
    }

    

    .menucontainer li:hover {
        cursor: pointer;
    }


}