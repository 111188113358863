/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

.NScontainer{
  background-color: #fff;
}

.NScontainer table {
  text-align: center;
  margin: auto;
  font-family: 'Poppins', sans-serif;
}

.NScontainer table td {
  width: 250px;
  text-align: center;
}

.NSlinks td {
  min-width: 250px;
}
.NSbrowserimage {
  height: 125px;
}

.NSbrowsername {
  font-family: 'Fredoka One', sans-serif;
  font-size: 25px;
}

.NSbrowserlink {

  text-decoration: none;
  color: #fff;
  line-height: 40px;
  margin: 10px 0px;
}
.NSbrowserbutton {
  width: 200px;
  border-radius: 80px;
  background-color: #212B36;
  margin: 15px auto;
}

.NSheading {
  text-align: center;
  font-family: 'Fredoka One', sans-serif;
  font-size: 70px;
  color: #378082;
  margin: 30px auto;
}

.NSmessage {
  font-family: 'Poppins', sans-serif;
  text-align: center;
  font-size: 24px;
  margin: 20px auto 40px;
}

.NSimage {
  text-align: center;
  margin: 20px ;
}

.NSimage img {
  text-align: center;
  width: 250px;
  margin: auto;
}