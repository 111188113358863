.faqbackgroundImage {
    background-repeat: no-repeat;
    background-position: bottom left;
    background-size: 310px;
    transition: all .5s linear;

}

.backgroundImageOpen {
    background-repeat: no-repeat;
    background-position: bottom left;
    background-size: 310px;
    -webkit-animation: scale-in-ver-top 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: scale-in-ver-top 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.faqFullsubHeading {
    text-align: center;
    max-width: 800px;
    margin: auto;
}

.help-support {
    display: flex;
    flex-direction: column;
    width: var(--screen-padding);
    margin: 0px auto 40px;
    text-align: center;
    position: relative;

}

.help-support-img {
    position: absolute;
    bottom: 0;
    left: 0;
    max-width: 30vw;
}

.help-support h1 {
    text-align: center;
}


.help-support input {
    min-width: 300px;
    max-width: 600px;
    width: 80%;
    padding: 10px 40px;
    margin: 10px auto;
    border-radius: 40px;
    border: 0;
    color: #000;
    font-size: 18px;
    background-color: #E5E5E5;
}

.faqAutoCompleteUl {
    text-align: left;
    min-width: 300px;
    max-width: 600px;
    width: 80%;
    padding: 10px 40px;
    margin: 10px auto;
    color: #000;
    font-size: 18px;
    position: absolute;
    top: 40px;
    left: 11%;
    z-index: 6;
    background-color: white;
    cursor: pointer;
}

.help-support em {
    background-color: var(--primary-color);
}


/* new accordian info */
.faqfullaccordian {
    margin: 10px auto 5px;
    width: 800px;
    min-width: 310px;
    font-family: var(--primary_font_family);
    text-align: left;


}

.faqfullhead {
    flex: 1;
    padding: 8px 10px 8px 30px;
    position: relative;
    overflow: hidden;
    display: flex;
    color: white;
    font-size: 16px;

}




.faqfullacchead {
    background-color: #387F81;
    border-radius: 50px 50px 50px 50px;
    font-size: 25px;
    font-weight: 600;
    display: flex;
    position: relative;
    z-index: 2;
    margin-top: -16px;
    margin-bottom: 15px;
}

.faqMaindetail {
    width: 96% !important;
    padding: 40px 20px 20px;
    background-color: white;
    color: black;
    margin-top: -30px;
    border-radius: 0 0 50px 50px;
    border: 5px solid #ffffff;
    font-size: 22px;
}


.close .faqMaindetail {
    height: 0px;
    transition: height 1s ease-out;
    -webkit-transform: scaleY(0);
    -o-transform: scaleY(0);
    -ms-transform: scaleY(0);
    transform: scaleY(0);
    /* float:left; */
    display: none;


}

.open .faqMaindetail {
    padding: 40px 20px 20px;
    width: 100%;
    display: block;
    box-sizing: border-box;
    background-color: #ffffffc9;
    border: none;
    border-radius: 4px;
    margin-left: 15px;
    -webkit-animation: scale-in-ver-top 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: scale-in-ver-top 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.fullaccordian {}

.faqChildContainer {
    display: flex;
}

.faqchildaccordionitem {
    width: 100%;
}

.closedchild .accordionItemHeading::after {
    content: "\2304";
    position: absolute;
    right: 10px;
    top: -10px;
    font-size: 23px;
}

.openedchild .accordionItemHeading::after {
    content: "\2303";
    position: absolute;
    right: 10px;
    top: 7px;
    font-size: 23px;
}


.faqchilsaccordionItemContent {
    text-align: left;
}

.closedchild .faqchilsaccordionItemContent {
    height: 0px;
    transition: height 1s ease-out;
    -webkit-transform: scaleY(0);
    -o-transform: scaleY(0);
    -ms-transform: scaleY(0);
    transform: scaleY(0);
    float: left;
    display: block;


}

.openedchild .faqchilsaccordionItemContent {
    padding: 20px;
    background-color: #fff;
    /* border: 1px solid #ddd; */
    width: 100%;
    margin: 0px;
    display: block;
    box-sizing: border-box;
    text-align: left;
    -webkit-animation: scale-in-ver-top 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: scale-in-ver-top 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.faqchildaccordionitem.close h3:after {
    content: "\2304";
    /* padding: 0px 8px; */
    right: 0;
    z-index: 1;
    position: absolute;
    top: -15px;
    font-size: 30px;
    text-align: center;
    height: 100%;
    pointer-events: none;
}

.faqchildaccordionitem.open h3:after {
    content: "\2303";
    font-size: 23px;
    /* padding: 0px 8px; */
    right: 0 !important;
    z-index: 1;
    position: absolute;
    top: 0 !important;
    text-align: center;
    height: 100%;
    pointer-events: none;
}

.open .faqfullhead::after {
    content: "\2303";
    position: absolute;
    right: 10px;
    top: 7px;
    font-size: 23px;

}

.close .faqfullhead::after {
    content: "\2304";
    position: absolute;
    right: 10px;
    top: -11px;
    font-size: 30px;

}

.faqchilsaccordionItemContent a {
    color: black;
    text-decoration: underline;
}

.faqchilsaccordionItemContent p {
    text-align: justify;
}

.faqchildaccordionitem .accordionItemHeading {
    font-size: 16px;
    font-weight: 600;
}

.faqchildaccordionitem .faqchilsaccordionItemContent {
    font-size: 16px;
    padding: 5px;
    color: grey;


}

.closedchild .faqchilsaccordionItemContent {
    display: none;
}

.helpLine {
    margin: 5px 0px;
}

.accordionItemHeading {
    position: relative;
}

.help-support input:focus {
    outline: 0
}

.scale-in-ver-top {}

@media all and (max-width: 500px) {

    .help-support {
        width: 92vw;
        padding: 0px !important;
        margin: 0px -2% !important;
    }

    .help-support input {
        max-width: 310px;
        min-width: unset;
        padding: 10px 10px;

    }

    .faqAutoCompleteUl {
        left: unset;
        padding: 10px 10px;
    }

    .help-support .fullaccordian {
        min-width: unset;
        width: 310px;
        margin: 10px 0px 5px !important;
    }

    .faqbackgroundImage
    {
        background-image: none !important;
    }


}

@media all and (max-width: 340px) {
    .help-support {
        width: 99vw;
        padding: 0px !important;
        max-width: 290px !important;
        margin: 0px -2% !important;
    }
}

.faqchilsaccordionItemContent li {
    list-style-type: unset;
}

.faqchilsaccordionItemContent em {
    background-color: transparent;
}

.faqtopleftimg {
    position: absolute;
    width: 134px;
    top: 50px;
    left: 60px;
  }
  
  .faqbottomleftimg {
    position: absolute;
    width: 146px;
    top: 515px;
    left: 60px;
  
  }
  
  .faqtoprightimg {
    position: absolute;
    width: 150px;
    top: 50px;
    right: 8%;
  }
  
  .faqbottomrightimg {
    position: absolute;
    width: 103px;
    top: 530px;
    right: 8%;
  }

@media only screen and (min-width: 1150px) and (max-width: 1300px) {
    .faqtopleftimg {
      position: absolute;
      width: 134px;
      top: 50px;
      left: 60px;
    }
  
    .faqbottomleftimg {
      position: absolute;
      width: 146px;
      top: 580px;
      left: 60px;
    }
  
    .faqtoprightimg {
      position: absolute;
      width: 150px;
      top: 50px;
      right: 40px;
    }
  
    .faqbottomrightimg {
      position: absolute;
      width: 103px;
      top: 530px;
      right: 40px;
    }
  }
  
  @media only screen and (min-width: 800px) and (max-width: 1150px) {
    .faqtopleftimg {
      position: absolute;
      width: 134px;
      top: 50px;
      left: 5px;
    }
  
    .faqbottomleftimg {
      position: absolute;
      width: 146px;
      top: 580px;
      left: 5px;
    }
  
    .faqtoprightimg {
      position: absolute;
      width: 150px;
      top: 50px;
      right: 5px;
    }
  
    .faqbottomrightimg {
      position: absolute;
      width: 103px;
      top: 530px;
      right: 5px;
    }
  }

  @media only screen and (max-width: 800px) {
    .faqtopleftimg {
        top: 5px;
        left: 0px;
        display: none;

      }
    
      .faqbottomleftimg {
        position: absolute;
        width: 146px;
        top: 400px;
        left: 5px;
        display: none;
      }
    
      .faqtoprightimg {
        position: absolute;
        width: 150px;
        top: 5px;
        right: 5px;
        display: none;

      }
    
      .faqbottomrightimg {
        position: absolute;
        width: 103px;
        top: 285px;
        right: 5px;
        display: none;
    
      }
  }

  @media only screen and (max-width: 950px) {

    
      .faqbottomleftimg {
        position: absolute;
        width: 146px;
        top: 400px;
        left: 5px;
        display: none;
      }
    
      .faqbottomrightimg {
        position: absolute;
        width: 103px;
        top: 285px;
        right: 5px;
        display: none;
    
      }
  }