.leftimagecard {
    display: flex;
    width: var(--screen-padding);
    margin: var(--component-margin);
    justify-content: space-between;
    padding: 3%;
    align-items: center;
    flex-wrap: wrap;

}

.leftcolumn {
    max-width: 30%;
    margin: auto;
    min-width: 300px;
}

.leftcolumn img {
    max-width: 75%;
    text-align: left;


}

.rightcolumn {
    max-width: 60%;
    min-width: 100%;
}



@media all and (min-width: 350px) {
    .rightcolumn {
     display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: var(--screen-padding);
    margin: var(--component-margin);
    align-items: center;
    justify-content: center;
    text-align: center;
    }

    .leftcolumn
    {
        text-align: center;
        /* min-width: 350px; */

    }

}

@media all and (min-width: 1200px) {
    .leftcolumn
    {
        text-align: left;
        align-items: flex-start;
    }
    .rightcolumn{
        text-align: left;
        align-items: flex-start;

        min-width: 310px;


    }
}

.floatimagetext {
    display: flex;
    flex-wrap: wrap;
    width: var(--screen-padding);
    margin: var(--component-margin);
    justify-content: space-evenly;
    align-items: center;
    flex-direction: row
}

.floatimagetext.topleft {
    flex-direction: row;
    align-items: flex-end;
}

.floatimagetext.bottomleft {
    flex-direction: row;
    align-items: flex-start;
}

.floatimagetext.topright {
    flex-direction: row-reverse;
    align-items: flex-end;
}

.floatimagetext.bottomright {
    flex-direction: row-reverse;
    align-items: flex-start;
}

.floatimagetext img {
    width: 100%;
}

.image-column {
    max-width: 45%;
    min-width: 300px;
    height: auto;
    z-index: 2;
    display: flex;
    justify-content: center;
    position: relative;
    align-items: center;
}

.image-column img{
    height: auto;
}

.text-column {
    width: 35%;
    min-width: 300px;
    z-index: 2;

}

.cta_button {
    text-align: center;
    display: inline-block;
    border-radius: 80px;
    background-color: black;
    color: var(--primary-font-color);
    text-transform: uppercase;
    padding: 7px;
    min-width: 170px;
    margin: 20px 15px 20px 0px;
}

.gap{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}



.headertextimage {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: var(--screen-padding);
    margin: var(--component-margin);
    align-items: center;
    justify-content: center;
    text-align: center;
}
.paragraph {
    margin-top: 0px;
}
.headertextimage img{
    width: 100%;
    height: auto;
}


.buttonRow{
    display: flex;
    flex-wrap: wrap;
}


.smallImageCards
{
    border: 1px solid grey;
    max-width: 30%;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    padding: 10px;
    justify-content: center;
    align-items: center;
    margin: 0px 8px 15px 8px;
    min-width: 150px;
}

.smallImageCards img{
    width: 200px;

}

