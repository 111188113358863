body {
        font-family: var(--primary_font_family);
        font-size: 16px;
        max-width: 1920px; 
        margin: auto;

}

li {
        padding-inline-start: 0px;
        list-style-type: none;
}

ul {
        padding-inline-start: 0px;
}

h1 {
        font-family: var(--secodary_font_family);
        font-weight: 400;
        font-size: clamp(20px, 5.5vw, 40px);
        margin-bottom: 20px;
        margin-top: 5px;
}

a {
        text-decoration: none;
        color: inherit;
}

.page-layout-component {
        width: calc(100% - 40px);
        /* background-color: var(--primary-color); */
        padding: var(--component-padding);
        position: relative;
}

.displaynone {
        display: none;
}


/* ----------------------------------------------
 * Generated by Animista on 2021-3-10 21:46:14
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation scale-in-ver-top
 * ----------------------------------------
 */
@-webkit-keyframes scale-in-ver-top {
        0% {
                -webkit-transform: scaleY(0);
                transform: scaleY(0);
                -webkit-transform-origin: 100% 0%;
                transform-origin: 100% 0%;
                opacity: 1;
        }

        100% {
                -webkit-transform: scaleY(1);
                transform: scaleY(1);
                -webkit-transform-origin: 100% 0%;
                transform-origin: 100% 0%;
                opacity: 1;
        }
}

@keyframes scale-in-ver-top {
        0% {
                -webkit-transform: scaleY(0);
                transform: scaleY(0);
                -webkit-transform-origin: 100% 0%;
                transform-origin: 100% 0%;
                opacity: 1;
        }

        100% {
                -webkit-transform: scaleY(1);
                transform: scaleY(1);
                -webkit-transform-origin: 100% 0%;
                transform-origin: 100% 0%;
                opacity: 1;
        }
}

/* ----------------------------------------------
 * Generated by Animista on 2021-3-10 21:54:20
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation scale-up-ver-bottom
 * ----------------------------------------
 */
@-webkit-keyframes scale-up-ver-bottom {
        0% {
                -webkit-transform: scaleY(0.4);
                transform: scaleY(0.4);
                -webkit-transform-origin: 0% 100%;
                transform-origin: 0% 100%;
        }

        100% {
                -webkit-transform: scaleY(1);
                transform: scaleY(1);
                -webkit-transform-origin: 0% 100%;
                transform-origin: 0% 100%;
        }
}

@keyframes scale-up-ver-bottom {
        0% {
                -webkit-transform: scaleY(0.4);
                transform: scaleY(0.4);
                -webkit-transform-origin: 0% 100%;
                transform-origin: 0% 100%;
        }

        100% {
                -webkit-transform: scaleY(1);
                transform: scaleY(1);
                -webkit-transform-origin: 0% 100%;
                transform-origin: 0% 100%;
        }
}

/* custome code css */
.tabs.light-green-tab input[type='radio']:checked+label {
        color: #fff;
}

.tabs.light-green-tab input[type='radio']+label {
        color: #59c0a1;
}

 .closedchild .intCallingaccordionItemContent
{
        display: none;
}